import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
@Injectable()
export class UserService {
constructor(public router: Router) {}

async storeData(data) {
    localStorage.setItem('userData', JSON.stringify(data));
    localStorage.setItem('isLoggedIn', '1');
    const newData = await this.getData();
    return this.router.navigate(['buyer-homepage'], newData);
}

getData() {
   return JSON.parse(localStorage.getItem('userData'));
}

sessionIn() {
   let A;
   if (this.getData()) {
       A = this.router.navigate(['buyer-homepage'], this.getData());
   }
   return A;
}

sessionOut() {
   let A;
   if (!this.getData()) {
     A = this.router.navigate(['']);
   }
   return A;
}

logOut() {
   localStorage.setItem('userData', '');
   localStorage.clear();
   return this.router.navigate(['']);
}
}