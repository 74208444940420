import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ApiService} from '../api.service';
import { Router } from '@angular/router';
import { UserService } from './services/user.service';


//import bootbox from 'bootbox';

  declare var bootbox:any;

@Component({
  selector: 'ngx-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
public responseData: any;
public userPostData = {
email: '',
firstName: '',
lastName: '',
provider: '',
provider_id: '',
provider_pic: '',
token: '',
login: '',
password: '',
authToken: '',
idToken: ''
};

  loginFormGroup: FormGroup;
  user: User;
  //socialUser: SocialUser;
  error: string;
  loading: boolean;
  private loggedIn: boolean;
  

  constructor(private _formBuilder: FormBuilder, 
    private apiService: ApiService, private _router: Router,
    public usersl: UserService) {
  }

  apiConnection(data) {
    this.userPostData.email = data.email;
    this.userPostData.firstName = data.firstName;
    this.userPostData.lastName = data.lastName;
    this.userPostData.login = data.email;
    this.userPostData.provider = data.provider;
    this.userPostData.provider_id = data.id;
    this.userPostData.provider_pic = data.imageUrl;
    this.usersl.storeData(data);

 
 }

  ngOnInit() {
    $('.spinner').hide();
    $('.spinner').css("display","none");
    this.loginFormGroup = this._formBuilder.group({
      uNameCtrl: ['', Validators.required],
      pwdCtrl: ['', Validators.required],
    });
  }
  
  loginUser() {
    if (this.loginFormGroup.invalid) {
      console.log('invalid data');
    } else {
      console.log('valid data');
      $('.spinner').show();
    $('.spinner').css("display","block");
      this.user = new User(this.loginFormGroup.get('uNameCtrl').value, this.loginFormGroup.get('pwdCtrl').value, false);
      this.apiService.loginUser(this.user)
        .subscribe(res => {
            // console.log('User Logged in successfully');
            // console.log(res['id_token']);
            // console.log(this.loginFormGroup.get('uNameCtrl').value);
            localStorage.setItem('userAccessToken', res['id_token']);
            this.apiService.getLoggedInUserData(res['id_token'])
            .subscribe(rest => {
              if(rest.userType=="Seller" || rest.userType=="Buyer"){
                $('.spinner').hide();
            $('.spinner').css("display","none");
            alert('Credentials do not match our records..!!' );
            this.loading = false;
            return false;
              }
                console.log('User Info Retrieved Successfully');
                console.log(rest);
                // res = res.filter(object =>{
                //   return object['login'] == this.loginFormGroup.get('uNameCtrl').value;
                // });
               // localStorage.setItem('isLoggedIn',true);
                localStorage.setItem('isLoggedIn', '1');
                localStorage.setItem('userData', JSON.stringify(rest['email']));
                localStorage.setItem('loggedVia','MongoDB');
                localStorage.setItem('userId',rest['id']);
                localStorage.setItem('userEmail',rest['email']);
                localStorage.setItem('userFname',rest['firstName']);
                localStorage.setItem('userLname',rest['lastName']);
                localStorage.setItem('userType', rest['userType']);
                localStorage.setItem('userNm', rest['login']);
                localStorage.setItem('userid', rest['id']);
                
                const userRoles = rest['userRoles']!=null ? JSON.stringify(rest['userRoles']) : '';
                const userPerms = rest['userRoles']!=null ? JSON.stringify(rest.userRoles[0].entityPermissionMap) : '';
                localStorage.setItem('userRoles', userRoles);
                localStorage.setItem('userPerms', userPerms);
                let userPerm = localStorage.getItem('userPerms');
                if(userPerm!=''){
                  let userPerms = JSON.parse(JSON.parse(userPerm));
                  localStorage.setItem('showMenu','some');
                  for (var key in userPerms) {
                    if (userPerms.hasOwnProperty(key)) {
                      var val = userPerms[key];
                      localStorage.setItem(key,val);
                    }
                  }
                }
                else{
                  localStorage.setItem('showMenu','all');
                  this._router.navigate(['/pages/admin-dashboard']);
                }

                if(localStorage.getItem('viewAdminDashboard')=='true'){
                  this._router.navigate(['/pages/admin-dashboard']);
                }
                else if(localStorage.getItem('viewOperationDashboard')=='true'){
                  this._router.navigate(['/pages/operations-dashboard']);
                }
                else if(localStorage.getItem('viewSaleDashboard')=='true'){
                  this._router.navigate(['/pages/sales-dashboard']);
                }
               
                else if(localStorage.getItem('viewContentWriterDasboard')=='true'){
                  this._router.navigate(['/pages/content-writer-dashboard']);
                }
                // else if(localStorage.getItem('viewFinanceDashboard')=='true'){
                //   this._router.navigate(['/pages/finance-dashboard']);
                // }
                // else if(localStorage.getItem('SEODasboard')=='true'){
                //   this._router.navigate(['/pages/seo-dashboard']);
                // }
                else{
                  this._router.navigate(['/pages/admin-dashboard']);
                }
                
                if(res['userType']=='Buyer'){
                  this._router.navigate(['/pages/admin-dashboard']);
                  console.log('I am a BUyer and need to stay in Buyer Homepage')
                }
                else if(res['userType']=='Seller'){
                  localStorage.setItem('sellerCity', res['sellerCity']);
                  localStorage.setItem('sellerCompanyAddress', res['sellerCompanyAddress']);
                  localStorage.setItem('sellerCompanyName', res['sellerCompanyName']);
                  localStorage.setItem('sellerCompanyNumber', res['sellerCompanyNumber']);
                  localStorage.setItem('sellerCompanyType', res['sellerCompanyType']);
                  localStorage.setItem('sellerCountry', res['sellerCountry']);
                  localStorage.setItem('sellerEmirate', res['sellerEmirate']);
                  localStorage.setItem('sellerInceptionYear', res['sellerInceptionYear']);
                  this._router.navigate(['/pages/admin-dashboard']);
                  console.log('I am a Seller and need to stay in Seller Homepage')

                }
                else{

                }
              },
                error => {
                  console.log(error);
                  this.error = error});
            // this._router.navigate(['/buyer-homepage']);
          },
          error => {
            console.log(error);
            this.error = error;
            
            $('.spinner').hide();
            $('.spinner').css("display","none");
            
            if(error && error.error && error.error.detail && error.error.detail.indexOf('Locked') >= 0){
              alert(error.error.detail );
            }else{
              alert('Credentials do not match our records..!!' );
            }
            this.loading = false;
          });

    }
  }
}

class User {
  username: string;
  password: string;
  isSocialLogin: boolean;

  constructor(username: string, password: string, isSocialLogin: boolean) {
    this.username = username;
    this.password = password;
    this.isSocialLogin = isSocialLogin;
  }
}

